.link{
  color: var(--primary-text-primary);
  outline: none !important;
}
.outerWrapper{
  background-color: var(--primary-background-primary);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.footerWrapper{
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sections{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionWrapper{
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.titleWrapper{
  width: 100%;
  border-bottom: 2px solid var(--primary-text-primary);
  margin-bottom: 1rem;
}
.sectionTitle{
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  color: var(--primary-text-primary);
  outline: none !important;
  font-family: var(--font-main);
}
.sectionContent{
  display: flex;
  flex-direction: column;
  align-items: start;
}
.sectionA, .sectionLink, .shopLink{
  color: var(--primary-text-primary) !important;
  outline: none !important;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.5rem;
  width: 100%;
  padding-left: 0;
}
.sectionA:hover, .sectionA:focus,
.sectionLink:hover, .sectionLink:focus,
.shopLink:hover, .shopLink:focus{
  text-decoration: underline;
  cursor: pointer;
}
.sectionA:focus, .sectionLink:focus, .shopLink:focus{
  outline: none !important;
}
.shopLink{
  color: var(--primary-text-primary);
  outline: none !important;
  width: 95%;
}
.logoWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo{
  width: 100%;
  max-width: 300px;
}
.copyrightWrapper{
  margin: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sectionLink{
  outline: none !important;
  display: flex;
  align-items: start;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
  .sections{
      display: grid;
      grid-template-columns: 1fr 1fr;
      place-items: start stretch;
  }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
  .sections{
      grid-template-columns: 1fr 1fr 1fr;
  }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}
