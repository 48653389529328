.homeWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    min-height: 3700px;
}
.links{
    display: flex;
    align-items: center;
    margin-top: 3rem;
    height: unset;
    flex-direction: column;
    gap: 1rem;
}
.linkWrapper{
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
.linkWrapper:hover {
    opacity: 80%;
}
.linkWrapper img {
    width: 250px;
    height: 250px;
}
.divider{
    width: 90vw;
    height: 1px;
}
.customerSideContainer{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
.processContainer{
    display: grid;
    grid-template-columns: calc(1.2rem + 40px) auto;
    grid-gap: 3.5rem 1.5rem;
    gap: 3.5rem 1.5rem;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.processContainer::before{
    content: '';
    position: absolute;
    top: 0.75rem;
    bottom: 0.9rem;
    left: calc(calc(1.2rem + 40px) / 2 - 0.25rem);
    width: 0.5rem;
    background: url('assets/images/svg/folyamat_vonal.svg') repeat-y;
    background-size: contain;
    z-index: -1;
}
.processIcons2{
    display: flex;
    align-self: start;
    padding: 0.6rem 0.7rem;
    border-radius: 2.5rem;
    background-color: var(--primary-background-secondary);
    box-shadow: 0 0 12px -5px var(--p-900);
}
.processDescription{
    text-align: left;
}
.processDescription h3{
    margin: unset;
    margin-top: 0rem;
}
.processDescription p{
    margin: 0.5em auto 0;
}
.processIcons{
    display: flex;
    align-self: start;
    padding: 0.9rem 0.9rem;
    border-radius: 2.5rem;
    background-color: var(--primary-background-secondary);
    box-shadow: 0 0 12px -5px var(--p-900);
}
.processEnd{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background-color: var(--primary-background-secondary);
    place-self: center;
}
.startButton{
    background: var(--primary-background-secondary);
    color: var(--primary-background-primary);
    border: none;
    width: 100%;
    height: 40px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-Radius: 5px;
    font-size: 1rem;
    align-self: center;
    transition: background 300ms;
    font-family: var(--font-main), Sans-serif, Arial;
}
.startButton:hover{
    opacity: 80%;
}
.description{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.styledLink{
    color: var(--primary-background-secondary);
}
.workflowImage{
    width: 100%;
}
.widgetContainer{
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.widget{
    width: 260px;
    padding: 1rem;
}
.pairContainer{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 1rem;
    margin: 2rem;
}
.list{
    list-style-image: linear-gradient(to left bottom, var(--primary-background-secondary), var(--primary-background-secondary));
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    font-family: var(--font-main);
}
.contactButton{
    background: var(--primary-background-secondary);
    color: var(--primary-background-primary);
    width: 250px;
    cursor: pointer;
    text-align: center;
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    border-Radius: 5px;
    font-size: 1rem;
    align-self: center;
    transition: background 300ms;
}
.contactButton:hover{
    background: var(--primary-background-primary);
    color: var(--primary-text-primary);
}
.description{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
  

/* -- Mobile landscape */
@media (min-width: 480px) {
    .homeWrapper{
        min-height: 2450px;
    }
    .divider {
        width: auto;
    }
    .startButton{
        margin-top: 0.4rem;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .links {
        height: 320px;
        flex-direction: row;
        gap: 5rem;
        margin-top: 5rem;
    }
    .linkWrapper img {
        width: 300px;
        height: 300px;
    }
    .customerSideContainer{
        flex-direction: row;
        gap: 3rem;
    }
    .workflowImage {
        width: 70%;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
    .homeWrapper{
        min-height: 3070px;
    }
    .customerSideContainer{
        padding: 5rem 2rem;
        gap: 5rem;
    }
    .pairContainer{
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr;
    }
    .contactButton{
        margin-right: 2rem;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .customerSideContainer{
        gap: 10rem;
    }
    .pairContainer{
        margin: 5rem 2rem;
        grid-gap: 5rem;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}