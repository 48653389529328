.container{
    width: 100%;
    height: 100vh;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainText{
    font-size: 7rem;
    font-weight: 600;
    color: var(--primary-text-content);
    margin-top: 5rem;
}
.secondaryText{
    font-size: 3rem;
    font-weight: 400;
    color: var(--primary-text-content);
    position: relative;
}
.secondaryText::after{
    content: "";
    border: 1px solid var(--primary-text-content);
    animation: blink 1000ms infinite;  
}

@keyframes blink {
    0%,
    49% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0;
    }
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}
/* -- Tablet portrait */
@media (min-width: 768px) {
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}