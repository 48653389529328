.itemWrapper{
    display: grid;
    grid-template-columns: 120px minmax(0, 1fr);
    gap: 24px;
    padding: 16px 0;
    border-bottom: 1px solid #e5e7eb;
    width: 100%;
    align-items: stretch;
}
.imageWrapper{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
}
.itemImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.topSection{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
    flex: 1;
}
.itemInfo{
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 0;
    overflow: hidden;
    width: 100%;
}
.itemName{
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.itemName a{
    color: inherit;
    text-decoration: none;
}
.itemName a:hover{
    color: #4b5563;
}
.itemDetails{
    display: flex;
    gap: 8px;
    color: #6b7280;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.deleteButton{
    padding: 8px;
    color: #6b7280;
    border-radius: 4px;
}
.deleteButton:hover{
    color: #111827;
    background: #f3f4f6;
}
.deleteButton svg{
    font-size: 18px;
}
.stockStatus{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #059669;
    font-size: 14px;
    margin-top: auto;
    padding-top: 8px;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .cartItem{
        grid-template-columns: 100px minmax(0, 1fr);
        gap: 16px;
    }
    .imageWrapper{
        width: 100px;
        height: 100px;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}