.dataGrid{
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
}
.dataBlock{
    background-color: #f9fafb;
    padding: 1.5rem;
    border-radius: 8px;
    border-bottom: 1px solid #e5e7eb;
    height: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.dataTitle{
    font-size: 0.875rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.75rem;
    font-family: var(--font-secondary);
}
.dataContent{
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.5;
    font-family: var(--font-secondary);
    flex: 1;
}
.dataContent p{
    margin: 0.25rem 0;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}
.paymentMethod{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.5rem;
}
.paymentMethod div{
    word-break: break-word;
    overflow-wrap: break-word;
}
.methodLogoImage{
    max-width: 80px;
    max-height: 50px;
}
.listItem{
    margin: 0 !important;
    font-family: var(--font-secondary), Sans-serif, Arial !important;
    border-top: 1px #e5e7eb solid !important;
    padding-left: 0 !important;
}
.imageWrapper{
    width: 90px;
    height: 90px;
    margin-right: 1rem;
    margin-left: 0;
    border-radius: 0.5rem;
    overflow: hidden;
}
.imageWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.listItemText{
    margin: 0 20px !important;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.typographyPrice1{
    font-family: var(--font-secondary), Sans-serif, Arial;
    margin: 0 20px;
}
.typographyPrice2{
    display: none !important;
}
.totalText{
    font-weight: 700 !important;
    font-family: var(--font-secondary), Sans-serif, Arial !important;
}
.data{
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.navigationButtons{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    margin: 1rem 0 !important;
    gap: 1rem !important;
}
.button{
    margin-top: 30px !important;
    font-weight: 600 !important;
    background: var(--primary-background-secondary) !important;
    color: var(--primary-background-primary) !important;
    transition: background 0.3s !important;
}
.button:hover{
    background: #e5a600 !important;
}
.button:disabled{
    background: lightgray !important;
    color: grey !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .dataGrid{
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
        max-width: 800px;
    }
    .typographyPrice1{
        display: none !important;
    }
    .typographyPrice2{
        display: unset !important;
        font-family: var(--secondary-font), Sans-serif, Arial;
        margin-left: auto;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}