.cardContainer{
    flex: 0 0 calc(100% - 16px);
    margin: 8px;
    max-width: 280px;
}
.cardWrapper{
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    background: var(--primary-background-content);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    cursor: pointer;
}
.cardWrapper:hover{
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
}
.imageWrapper{
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
}
.imageWrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    opacity: 0;
    transition: opacity 0.3s ease;
}
.cardWrapper:hover, .imageWrapper::after {
    opacity: 1;
}
.cardImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.cardWrapper:hover .cardImage {
    transform: scale(1.05);
}
.placeholder{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-background-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    transition: opacity 0.3s ease;
}
.cardPlaceholder::before {
    content: '';
    position: absolute;
    width: 64px;
    height: 64px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}
.cardWrapper:hover .cardPlaceholder {
    opacity: 1;
}
.categoryBadge{
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 2rem;
    font-size: 0.75rem;
    backdrop-filter: blur(4px);
    z-index: 1;
}
.cardContent{
    padding: 0.75rem;
    background: var(--primary-background-secondary);
}
.title{
    font-family: var(--font-secondary), Sans-serif, Arial !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    color: var(--primary-background-primary);
    text-align: center;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.25rem 0;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .cardContainer {
        flex: 0 0 calc(50% - 16px);
        max-width: 280px;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardContainer {
        flex: 0 0 calc(33.333% - 16px);
        max-width: 280px;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardContainer {
        flex: 0 0 calc(25% - 16px);
        max-width: 280px;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}