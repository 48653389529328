.fieldWrapper{
    width: wrap-content;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    align-items: center;
}
.input {
    min-width: 100%;
}
.formContainer{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 70%;
    gap: 1rem;
    align-items: center;
}
.loginButton{
    background: var(--primary-background-primary);
    color: var(--primary-text-primary);
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 20px;
    font-size: 1rem;
    align-self: center;
    transition: background 300ms;
    font-family: var(--font-main), Sans-serif, Arial;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .formContainer{
        flex-direction: row;
        width: 100%;
    }
    .loginButton {
        margin-top: 0.4rem;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .fieldWrapper{
        align-items: unset;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}