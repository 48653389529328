.introSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: var(--primary-background-secondary);
}
.introSectionInner{
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    align-items: center;
}
.heroSection{
    display: flex;
    align-items: center;
    margin: 1rem 0 2rem 0;
    flex-direction: column;
}
.introText{
    color: var(--primary-background-primary);
    font-family: var(--font-main), Sans-serif, Arial;
}
.introText h1 {
    line-height: 2.5rem;
    font-size: 28px;
}
.introText p {
    font-size: 18px;
    line-height: 1.75rem;
}
.underline{
    text-decoration-color: var(--primary-dark);
    text-underline-offset: 5px;
}
.portraitWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}
.portraitInner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid var(--primary-background-primary);
    background-color: var(--primary-background-content);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
    border-radius: 50%;
    overflow: hidden;
    width: 200px;
    height: 200px;
}
.portraitImage{
    width: 60vw;
    max-width: 200px;
    margin: 0 auto;
}
.introTitle{
    font-weight: 600;
    text-align: center;
    margin: 1rem;
    text-transform: uppercase;
    line-height: 90%;
    margin-top: 2rem;
    color: var(--primary-background-primary);
    font-family: var(--font-main), Sans-serif, Arial;
    font-size: 28px;
}
.blendeImageWrapper2{
    display: hidden;
    position: absolute;
}
.blendeImageWrapper{
    display: hidden;
    position: absolute;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .introText h1 {
        font-size: 35px;
        line-height: 3rem;
    }
    .introTitle {
        font-size: 35px;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .introSectionInner{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .heroSection{
        margin: 4rem 0 4rem 0;
        gap: 3rem;
        flex-direction: row;
    }
    .portraitInner{
        width: 255px;
        height: 255px;
    }
    .portraitImage{
        max-width: 250px;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
    .introSection{
        padding: 5rem 0;
    }
    .heroSection{
        margin: 1rem 0 1rem 0;
    }
    .introText h1 {
        font-size: 45px;
        line-height: 4rem;
    }
    .introTitle {
        font-size: 45px;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .heroSection{
        gap: 8rem;
    }
    .blendeImageWrapper2{
        display: block;
        height: 50%;
        width: 250px;
        top: 0;
        left: 0;
        margin-bottom: 1.5rem;
    }
    .blendeImageWrapper{
        display: block;
        height: 980px;
        width: 980px;
        bottom: 0;
        right: 0rem;
        position: absolute;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}