.layout{
    width: auto;
    max-width: 950px;
}
.contentWrapper{
    margin: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    position: relative;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e7eb;
}
.headerTitle{
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
    font-family: var(--font-secondary), Arial, Helvetica, sans-serif;
}
.stepper {
    padding: 0 !important;
    margin-bottom: 32px !important;
}
.stepContainer{
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 24px;
    border: 1px solid #e5e7eb;
}
.dialogTitle{
    color: #313846;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 1rem 0.75rem 1rem;
}
.summaryWrapper{
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 24px;
    height: fit-content;
    margin-top: 32px;
    max-width: 100%;
}
.summaryTitle{
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 24px 0;
}
.summaryItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    color: #4b5563;
}
.summaryItem:not(:last-child) {
    border-bottom: 1px solid #e5e7eb;
  }
.checkoutButton{
    width: 100%;
    padding: 12px 24px;
    background: #ffb800;
    color: #111827;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-top: 24px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: var(--font-secondary), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif
}
.checkoutButton:hover{
    background: #e5a600;
}
.checkoutButton:disabled{
    background: #e5e7eb;
    color: #9ca3af;
    cursor: not-allowed;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .layout{
        margin: 2rem auto;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .contentWrapper{
        grid-template-columns: 2fr 1fr;
        align-items: start;
    }
    .summaryWrapper{
        position: sticky;
        top: 24px;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
    .summaryWrapper{
        max-width: 400px;
        margin-top: 4rem;
    }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}