.outsideCard {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    width: 100%;
}
.mainContainer {
    width: 90%;
    max-width: 450px;
    border-radius: 20px;
    
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    border: solid lightgray 2px;
    font-family: var(--font-main);
}
.mainContainerOutlined {
    width: 90%;
    height: 100%;
    max-width: 450px;
    border-radius: 20px;
    border: solid var(--primary-background-secondary) 2px;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    font-family: var(--font-main);
    position: relative;
}
.current_banner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: var(--primary-background-secondary);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.introButton {
    background: var(--primary-background-secondary);
    color: var(--primary-text-primary);
    border: none;
    height: 35px;
    padding: 0 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 2rem;
    font-size: 1rem;
    align-self: center;
    transition: background 300ms;
    font-family: var(--font-main), Sans-serif, Arial;
    color: black;
    margin-top: 1rem;
}
.introButton:hover {
    opacity: 80%;
}
.introButton:disabled {
    opacity: 60%;
    cursor: unset;
}
.fadeIn {
    opacity: 1;
    max-height: 100%;
    animation: fadeIn 0.25s ease-in-out forwards;
}
.fadeOut {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    animation: fadeOut 0.25s ease-in-out forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}
.innerPart {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    text-align: center;
    height: 100%;
}
.logoContainer {
    width: 125px;
}
.infoSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 0.8rem;
}

.info_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.payment {
    font-weight: bold;
    font-size: 20px;
}

.detailedContainer {
    display: flex;
    column-gap: 2rem;
}
.details {
    display: flex;
    row-gap: 2rem;
    flex-direction: column;
    font-family: var(--font-main);
}
.sentenceContainer {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
}

@media (min-width: 480px) {
   

/* -- Tablet portrait */
@media (min-width: 768px) { 
    .outsideCard{
        width: 48%;
    }
}
}

/* -- Tablet landscape */
@media (min-width: 992px) {
  
}

/* -- Laptop (HD) */
@media (min-width: 1200px) { 
    .outsideCard{
        width: 23%;
    }
    .mainContainer{
        width: 100%;
    }
    .mainContainerOutlined{
        width: 100%;
    }
    .info_item{
        font-size: 0.8rem;
    }
}
@media (min-width: 1300px) {
    .info_item{
        font-size: unset;
    }
}


/* -- Laptop (HD) */
@media (min-width: 1600px) {
   
}

/* -- FullHD */
@media (min-width: 1921px) {
}