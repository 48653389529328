.dialog{
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 30, 0.22);
}
.dialog .MuiDialog-paper {
    width: 100%;
    max-width: 1000px;
    padding: 2rem;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;
    background: var(--primary-text-primary);
  }
.title{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary-background-primary);
    font-family: var(--font-main), Sans-serif, Arial;
}
.description{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary-background-primary);
    margin: 1rem 1rem 0.5rem 1rem;
    text-transform: none;
    font-family: var(--font-secondary), Sans-serif, Arial;
    display: block;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
}
.stepper{
    display: flex;
}
.stepIndicator{
    margin: 2rem 0.3rem 0 0.3rem;
    width: 7px;
    height: 7px;
    border-radius: 7px;
}
.buttonsWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 2rem;
}
.backButton{
    width: 100%;
    color: var(--primary-text-primary) !important;
    opacity: 0.8;
    transition: 300ms!important;
    margin-top: 2rem !important;
}
.backButton:hover{
    opacity: 1;
    background: var(--primary-background-primary) !important;
}
.button{
    width: 100%;
    color: var(--primary-text-primary) !important;
    background: var(--primary-background-primary) !important;
    opacity: 0.8;
    transition: 300ms !important;
    margin-top: 2rem !important;
}
.button:hover{
    opacity: 1;
    background: var(--primary-background-primary) !important;
}