.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 4rem;
    background-color: rgb(241, 245, 249);
    padding-top: 1rem;
    padding-bottom: 5rem;
}
.title{
    font-size: 1.5rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;
    box-sizing: border-box;
    min-height: 575px;
    max-width: 1300px;
    width: 100%;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardContainer{
        justify-content: space-between;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}