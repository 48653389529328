.container{
    border-radius: 1.25rem !important;
    border: none;
    box-shadow: none;
    position: unset;
}
.container::before{
    display: none !important;
}
.title{
    margin: 1rem;
    font-size: 14px;
    font-family: Roboto;
}
.hashtag{
    color: var(--primary-background-secondary)
}
.details{
    display: flex;
    flex-direction: column;
    margin: 0 1rem 1rem 1rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .title{
        font-size: 16px;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}