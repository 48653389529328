.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgb(241, 245, 249);
    align-items: center;
}
.mainContainer section{
    margin: unset;
}
.accordionContainer{
    margin: 2rem 0rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.list{
    list-style-image: linear-gradient(to left bottom, var(--primary-background-secondary), var(--primary-background-secondary));
    display: flex;
    flex-direction: column;
    font-family: var(--font-main), Sans-serif, Arial;
    row-gap: 0.5rem;
}
.styledLink{
    color: var(--primary-background-secondary)
}
.priceTableWrapper{
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
}
.table{
    width: 100%;
    min-width: 300px;
    margin: 0;
    padding: 0;
    border: 1px solid var(--primary-background-secondary);
    border-radius: 2rem;
    overflow: hidden;
}
.thead{
    background: var(--primary-background-secondary);
    margin: 0;
    padding: 0;
}
.rowItem{
    margin: 0;
    padding: 0;
    text-align: center;
    border: 1px solid var(--primary-background-secondary);
}
.tbody{
    margin: 0;
    padding: 0;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .accordionContainer{
        margin: 3rem 1rem;
    }
}
/* -- Tablet portrait */
@media (min-width: 768px) {
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}