.center{
    padding-inline: 1.5rem;
}
.contactContainer{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
}
.portraitWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}
.portraitImage{
    width: 30vw;
    height: 30vw;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
    border: 2px solid var(--primary-background-primary);
    background-color: var(--primary-background-content);
}
.name{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--font-main), Sans-serif, Arial;
    text-align: center;
}
.datas{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}
.contactIllustration{
    width: 90%;
    margin-top: 1rem;
}
.formContainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.verticalDivider{
    display: none;
}
.horizontalDivider{
    display: unset;
}
.formWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
.submitButton{
    margin-top: 1rem !important;
    color: white !important;
    background-color: var(--primary-background-primary) !important;
}
.submitButton:disabled {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}
/* -- Tablet portrait */
@media (min-width: 768px) {
    .contactContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 5rem 0;
        gap: 1rem;
    }
    .header {
        flex-direction: row;
        gap: 2rem;
    }
    .formContainer {
        flex-direction: row;
    }
    .verticalDivider {
        display: block;
    }
    .horizontalDivider {
        display: none;
    }
}
/* -- Tablet landscape */
@media (min-width: 992px) {
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .center {
        padding-inline: calc(50% - 40.25rem);
    }
}
/* -- Laptop (HD) */
@media (min-width: 1600px) {
}
/* -- FullHD */
@media (min-width: 1921px) {
}