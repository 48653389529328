.counterWrapper{
    display: flex;
    align-items: center;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background: white;
    height: 32px;
    width: fit-content;
    min-width: 100px;
}
.countButton{
    padding: 4px !important;
    color: #6b7280 !important;
    border-radius: 6px !important;
}
.countButton:hover{
    background-color: #f3f4f6 !important;
    color: #111827 !important;
}
.countButton:disabled{
    color: #d1d5db !important;
}
.countButton svg{
    font-size: 16px;
}
.countDisplay{
    flex: 1;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #111827;
    user-select: none;
    font-family: var(--font-secondary), -apple-system, sans-serif;
    min-width: 24px;
}