.customButton {
    background-color: var(--primary-background-secondary) !important;
    border-color: var(--primary-background-secondary) !important;
    color: black !important;
    text-transform: uppercase !important;
    font-size: 0.7rem !important;
    letter-spacing: 0.1rem !important;
    line-height: 1.54 !important;
    transition: 250ms ease-in !important;
  }
  
  .customButton:hover,
  .customButton:focus {
    background-color: var(--text-primary);
    color: var(--primary-background-primary);
  }
  
  .customDialogTitle {
    /* font-size: 0.65rem !important; */
    /* letter-spacing: 0.1rem !important; */
    line-height: 1.54 !important;
    font-family: var(--font-secondary), Sans-serif, Arial;
  }
  .dialogActions{
    margin: 0 auto;
  }
  
  .card {
    margin: 2rem auto;
    max-width: 950px;
    height: 100%;
  }
  
  .link {
    cursor: pointer;
  }
  
  .breadcrumb {
    margin: 1rem;
    font-family: var(--font-main), Sans-serif, Arial;
  }
  
  /* Cart layout */
  .cartWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
  }
  
  .itemsWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  
  .groupWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  
  .groupName {
    font-weight: 400 !important;
    color: black !important;
    font-size: 1rem !important;
    align-self: flex-start !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    margin: 0 !important;
    font-family: var(--font-secondary), Arial, Helvetica, sans-serif !important;
  }
  
  .headerAndItems {
    width: 100%;
    padding: 0;
  }
  
  .cartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    width: 100%;
    padding-bottom: 21px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .cartTitle {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
    font-family: var(--font-secondary), Arial, Helvetica, sans-serif;
  }
  
  .itemCount {
    font-size: 16px;
    color: #6b7280;
    font-weight: 500;
    font-family: var(--font-secondary);
  }
  
  .buttonsWrapper {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    width: 100%;
  }
  
  .cartButton {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: var(--font-secondary), Arial, Helvetica, sans-serif;
  }
  
  .cartButtonOutlined {
    background: #f3f4f6;
    border: 1px solid #e5e7eb;
    color: #111827;
  }
  
  .cartButtonOutlined:hover {
    background: #e5e7eb;
  }
  
  .cartButtonPrimary {
    background: #ffb800;
    border: none;
    color: #111827;
  }
  
  .cartButtonPrimary:hover {
    background: #e5a600;
  }
  
  .groupList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
  
  .itemList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  
  .summaryWrapper {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 24px;
    height: fit-content;
    max-width: 400px;
    margin-top: 3rem;
  }
  
  .summaryItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    color: #4b5563;
  }
  
  .summaryItem:not(:last-child) {
    border-bottom: 1px solid #e5e7eb;
  }
  
  .summaryTitle {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 24px 0;
  }
  
  .checkoutButton {
    width: 100%;
    padding: 12px 24px;
    background: #ffb800;
    color: #111827;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-top: 24px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: var(--font-secondary);
  }
  
  .checkoutButton:hover {
    background: #e5a600;
  }
  
  .checkoutButton:disabled {
    background: #e5e7eb;
    color: #9ca3af;
    cursor: not-allowed;
  }
  
  .emptyCartMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 24px;
    text-align: center;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    margin: 24px 0;
  }
  
  .emptyCartMessage h3 {
    font-size: 20px;
    font-weight: 500;
    color: #111827;
    margin: 0 0 8px 0;
    font-family: var(--font-secondary);
  }
  
  .emptyCartMessage p {
    font-size: 14px;
    color: #6b7280;
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .cartWrapper {
      grid-template-columns: 2fr 1fr;
      gap: 32px;
      align-items: start;
    }
  
    .summaryWrapper {
      position: sticky;
      top: 1.5rem;
    }
  }
  
  @media (max-width: 1000px) {
    .summaryWrapper {
      margin-top: 32px;
      max-width: 100%;
    }
  }
  
  @media (max-width: 640px) {
    .buttonsWrapper {
      flex-direction: column;
    }
  }