.breadcrumbWrapper{
    padding: 1rem 0;
    font-family: var(--font-main), Sans-serif, Arial;
    background: var(--primary-background-primary);
    box-shadow: inset 0 6px 15px 0 rgba(0, 0, 0, 0.3);
    outline: none !important;
}
.breadcrumb{
    margin-left: 1rem;
    color: var(--font-main);
}
.rootLink{
    cursor: pointer;
    font-family: var(--font-secondary), Sans-serif, Arial;
    color: var(--primary-text-primary) !important;
}
.innerWrapper{
    width: 100%;
    color: var(--primary-text-primary) !important;
}
.innerBreadcrumb{
    color: var(--primary-text-primary) !important;
}
.link{
    cursor: pointer;
    color: var(--primary-text-primary) !important;
    text-overflow: ellipsis;
    max-width: 20ch;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}
.link:hover{
    text-decoration: underline;
}
.stickyWrapper{
    position: fixed;
    top: -80px;
    width: 100%;
    transform: translateY(80px);
    font-family: var(--font-main), Sans-serif, Arial;
    z-index: 1050;
    padding: 1rem 0;
    margin-top: 75px;
    color: var(--primary-text-primary) !important;
    background: var(--primary-background-primary) !important;
    border: 2px solid transparent;
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-transition: all 0.7s linear;
    transition: all 0.7s linear;
}
.stickyBreadcrumb{
    margin-left: 1rem;
    color: var(--primary-text-primary) !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .stickyWrapper{
        margin-top: 0;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}