.chooserCard {
    width: 100%;
    max-width: 950px;
    margin: 0 auto 24px auto;
    padding: 0 1rem;
    box-sizing: border-box;
}
.chooserTitle {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 16px 0;
}
.chooserWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.methodCard {
    width: 100%;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #ffffff;
}
.methodCard:hover {
    border-color: #d1d5db;
    background: #f9fafb;
}
.selectedMethod {
    width: 100%;
    border: 2px solid #ffb800;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #fffbeb;
}
.methodContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.methodHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.methodName {
    font-size: 15px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 4px;
}
.methodDescription {
    font-size: 14px;
    color: #6b7280;
}
.checkIcon {
    color: #ffb800;
    font-size: 20px;
}
.price {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
}
/* Make RadioGroup take full width */
.chooserWrapper > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* -- Tablet landscape */
@media (min-width: 992px) {

    .chooserCard {
        padding: 0 36px;
    }
}