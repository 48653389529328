.navigationButtons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
    gap: 1rem;
}
.button{
    background: var(--primary-background-secondary) !important;
    color: var(--primary-background-primary) !important;
}
.button:hover{
    background: #e5a600 !important;
}