
.header_section{
  position: relative;
}
.header_title {
  font-size: 40px;
  color: black;
}
.widget_container {
  margin: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.hero {
  display: flex;
  padding: 1rem 0 5rem 0;
}
.institution_image {
  display: none;
}
.section_padding {
  padding: 5rem 0;
}
.problems {
  padding: 1rem 0 5rem;
}
.arrow_image {
  display: block;
  margin: 1rem auto;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frustrated_image {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}
.button {
  width: 100%;
}
.imageWrapper1{
  display: hidden;
  position: absolute;
}
.imageWrapper2{
  display: hidden;
  position: absolute;
}
.introButton{
  background: var(--primary-background-primary);
  color: var(--primary-contrast-text);
  border: none;
  height: 56px;
  padding: 0 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family: var(--font-main), Sans-serif, Arial;
  margin-top: 1rem;
  width: 100%;
}
.introButton:hover{
  opacity: 80%;
}
.otherButton{
  color: var(--primary-background-primary);
  border: 1px solid var(--primary-background-primary);
  height: 56px;
  padding: 0 2rem;
  background: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family: var(--font-main), Sans-serif, Arial;
  margin-top: 1rem;
  width: 100%;
}
.otherButton:hover{
  opacity: 80%;
}
.link{
  color: var(--primary-background-secondary)
}

@media (min-width: 480px) {
  .buttons {
    flex-direction: row;
    gap: 0.5rem;
  }
  .introButton{
    width: unset;
  }
  .otherButton{
    width: unset;
  }
}

@media (min-width: 768px) {
  .frustrated_image {
    height: 300px;
    width: auto;
  }
  .widget_container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}
@media (min-width: 1100px) {
  .frustrated_image {
    height: 300px;
    width: auto;
  }
  .section_padding {
    padding: 5rem 0;
  }

  .container {
    flex-direction: row;
    gap: 3rem;
  }
  .hero {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 4.63rem 0;
  }
  .header_title {
    font-size: 45px;
  }
  .institution_image {
    margin: auto;
    display: block;
    margin-bottom: 0rem;
    background-color: white;
    border-radius: 50%;
    z-index: 100;
  }
  .widget_container {
    grid-gap: 5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .custom_center {
    margin: 0 !important;
  }
  .imageWrapper1{
    display: block;
    height: 980px;
    width: 980px;
    bottom: 0;
    right: 0rem;
    position: absolute;
  }
  .imageWrapper2{
    display: block;
    height: 50%;
    width: 250px;
    top: 0;
    left: 0;
  }
}
