.mainContainer{
    height: 100vh;
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paper{
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-background-secondary) !important;
    border-radius: 2rem !important;
}
.portraitInnerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-background-content);
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.portraitImage{
    width: 60vw;
    max-width: 100px;
    margin: 0 auto;
}
.title{
    color: whitesmoke;
    width: 90%;
}
.description{
    width: 90%;
    color: whitesmoke;
    margin-bottom: 2rem;
}