.paymentDetailsWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 3rem 0;
  padding: 0;
}
.paymentDetailsCard{
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 800px;
  height: 700px;
  position: relative;
  z-index: 1;
}
.loadingMessage{
  align-self: center;
  text-align: center;
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.title{
  font-family: var(--font-secondary), Sans-serif, Arial;
  background-color: var(--primary-background-secondary);
  max-width: 800px;
  min-height: 300px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  font-family: var(--font-main), Sans-serif, Arial;
}
.iconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.successCheck{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: whitesmoke;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--primary-success);
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.successCheckCircle{
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--primary-success);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.succcesCheckCheck{
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.cardTitle{
  color: var(--primary-background-primary);
  font-size: 32px;
}
.paymentDetailsBody{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 0 0 10px 10px;
  max-width: 800px;
  background: whitesmoke;
  height: 350px;
  padding: 10px;
  margin: 0 0 100px 0;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.detailsTitle{
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.detailsMessage{
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
  width: 100%;
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.IdText{
  text-align: center;
  width: 80%;
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.homeButton{
  margin: auto 0;
  width: 300px;
  text-align: center;
  font-size: 2rem;
  border: 1px solid lightgrey;
  border-radius: 11px;
  background: var(--primary-background-secondary);
  color: var(--primary-background-primary);
  padding: 0.25rem 0;
  transition: 0.2s linear;
  text-decoration: none;
  font-family: var(--font-secondary), Sans-serif, Arial;
}
.homeButton:hover{
  cursor: pointer;
  background: var(--primary-background-primary);
  color: var(--primary-background-secondary);
  transition: 0.2s linear;
}
.failIconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  background: crimson;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
.failIcon{
  color: whitesmoke;
  font-size: 50px;
  animation: fail 1s infinite;
}
.failedBody{
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 0 0 10px 10px;
  max-width: 800px;
  background: whitesmoke;
  height: 350px;
  padding: 10px;
  margin: 0 0 100px 0;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 40px #7ac142;
  }
}

@keyframes fail {
  50% {
    font-size: 60px;
  }
}