.container{
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
}
.iconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.successCheck{
    width: 52px;
    height: 52px;
}
.successCheckCircle{
    stroke: #4bb543;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.succcesCheckCheck{
    stroke: #4bb543;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}
@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
}
.cardTitle{
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-top: 1rem;
    text-align: center;
}
.orderDetails{
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
}
.detailsTitle{
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1.5rem;
}
.detailsMessage{
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}
.detailRow{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #e5e7eb;
}
.detailRow:last-child{
    border-bottom: none;
}
.detailLabel{
    font-size: 0.875rem;
    color: #6b7280;
    width: 120px;
    flex-shrink: 0;
}
.detailValue{
    font-size: 0.875rem;
    color: #111827;
    font-weight: 500;
    flex: 1;
}
.copyButton{
    padding: 4px;
    min-width: unset;
    color: #6b7280;
    transition: color 0.3s, background-color 0.3s;
}
.copyButton:hover{
    color: #111827;
    background-color: #f3f4f6;
}
.homeButton{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    margin-top: 2rem;
    background-color: #ffb800;
    color: #111827;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;
}
.homeButton:hover{
    background-color: #e5a600;
}

@media (max-width: 600px) {
    .container{
        padding: 0;
        padding-bottom: 2rem;
        max-width: 300px;
    }
    .detailRow{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .detailLabel{
        width: 100%;
    }
}